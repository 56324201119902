import { Flex } from 'components/box';
import { Heading, Text } from 'components/typography';
import { Link } from 'components/configurable-routing';
import Kitten from './kitten.png';
import { css } from '@emotion/react';

const ErrorKitten = () => (
  <Flex flexDirection={['column', null, 'row']}>
    <div
      css={theme => css`
        padding-right: ${theme.space[4]}px;
      `}
    >
      <img
        src={Kitten.src}
        alt="Error Kitten"
        css={theme => css`
          @media ${theme.mediaQueries.desktopUp} {
            max-width: 70%;
            height: auto;
            margin-left: auto;
            margin-right: auto;
            display: block;
          }
        `}
      />
    </div>
    <div
      css={theme => css`
        padding-left: ${theme.space[4]}px;
      `}
    >
      <Heading
        color="primary"
        fontSize={['xl', '2xl']}
        mb={4}
        css={css`
          text-transform: uppercase;
        `}
      >
        This is probably not what you were looking for, but isn&apos;t he just
        so cute?
      </Heading>
      <Text mb={4}>
        <em>
          (We keep him around for unexpected situations like this 404 error.)
        </em>
      </Text>
      <Heading
        color="primary"
        mb={4}
        css={css`
          text-transform: uppercase;
        `}
      >
        You now have 2 options
      </Heading>
      <Text mb={4} lineHeight={1.7}>
        Get lost in the warm cuteness of our furry friend&apos;s gaze and allow
        his face to melt away all the feelings of frustration caused by this
        unfortunate internet glitch.
      </Text>
      <Heading
        color="primary"
        mb={4}
        css={css`
          text-transform: uppercase;
        `}
      >
        <em>Or</em>
      </Heading>
      <Text mb={4}>
        Click{' '}
        <Link href="/" color="accent">
          here
        </Link>{' '}
        and return to our home page.
      </Text>
    </div>
  </Flex>
);

export default ErrorKitten;
