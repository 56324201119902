import { Container } from 'components/box';
import ErrorKitten from 'components/error-kitten';
import RelatedProducts from 'components/_pages/products/related-products';

const Error404Page = () => (
  <Container>
    <ErrorKitten />
    <RelatedProducts hideRelated customTitle="See something you like?" />
  </Container>
);

export default Error404Page;
